import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
// import { HashRouter,Routes,  Route, Link } from 'react-router-dom';
// import {Routes, Route} from 'react-router-dom';


import Home from './layout/Home';
import ProfileSetting from './layout/ProfileSetting';
import Terms from './layout/Terms';
import Login from './layout/Login';
import ForgotPassword from './layout/ForgotPassword';
// import Liveexam from './layout/Liveexam';
import UpcomingLiveExam from './layout/UpcomingLiveExam';
import UpcomingPracticeExam from './layout/UpcomingPracticeExam';
import LiveExam from './layout/LiveExam';
import LiveScore from './layout/LiveScore';

import StartLiveExam from './layout/StartLIveExam';
import StartPracticeExam from './layout/StartPracticeExam';
import PracticeExam from './layout/PracticeExam';
import PracticeScore from './layout/PracticeScore';
import LiveScoreView from './layout/Livescoreview';
import MonthWiseScore from './layout/MonthWiseScore';

import PaymentHistory from './layout/PaymentHistory';
import ChangePassword from './layout/ChangePassword';
import FileUpload from './layout/FileUpload';
import UpdatePassword from './layout/UpdatePassword';
import Register from './layout/Register';
import SuccessStory from './layout/SuccessStory';
import ViewChart from './content/ViewChart';
import MonthWiseBarGraphContent from './content/MonthWiseBarGraphContent';
import ReactBarChart2 from './layout/ReactBarChart2';
import PracticeExamQA from './layout/PracticeExamQA';
import LiveExamQA from './layout/LiveExamQA';

// import {useNavigate} from "react-router-dom";

function App() {

  // let navigate = useNavigate();

  // function getchecklogin() {
  //   if (localStorage.getItem("tokenID")!=null) {
  //     // let path = `/dashboard`; 
  //     // navigate(path);
  //   }
  //   //  else {
  //   //   let path = `/dashboard`; 
  //   //   navigate(path);
  //   // }
  // }

  function openFullscreen() {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
      document.documentElement.msRequestFullscreen();
    }
  }

  useEffect(()=>{
    // getchecklogin();
    openFullscreen();
  }, []);


  

  return (
    <>
    {/* <BrowserRouter> */}
        <Routes>
          <Route exact path='/' element={<Terms />} />
          <Route exact path='/dashboard' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/forgotpassword' element={<ForgotPassword />} />
          <Route exact path='/upcomingliveexam' element={<UpcomingLiveExam />} />
          <Route exact path='/liveexam/:qsl' element={<LiveExam />} />
          <Route exact path='/livescore' element={<LiveScore />} />
          {/* <Route exact path='/livescoreview/:suid/:livexmid' element={<LiveScoreView />} /> */}
          <Route exact path='/livescoreview/:curdate/:livexmid' element={<LiveScoreView />} />
        
          <Route exact path='/upcomingpracticeexam' element={<UpcomingPracticeExam />} />
          <Route exact path='/practiceexam/:qsl' element={<PracticeExam />} />
          <Route exact path="/practicescore" element={<PracticeScore/>} />
          
          <Route exact path='/profilesetting' element={<ProfileSetting />} />

          <Route exact path='/startliveexam' element={<StartLiveExam />} />
          <Route exact path='/startpracticeexam' element={<StartPracticeExam />} />

          <Route exact path='/monthwisescore' element={<MonthWiseScore />} />

          <Route exact path='/paymenthistory' element={<PaymentHistory />} />

          <Route exact path='/changepassword' element={<ChangePassword />} />

          <Route exact path='/updatepassword' element={<UpdatePassword />} />

          <Route exact path='/fileupload' element={<FileUpload />} />

          <Route exact path='/successstory' element={<SuccessStory />} />

          <Route exact path='/viewchart' element={<ViewChart />} />
          <Route exact path='/reactbarchart2' element={<ReactBarChart2 />} />

          <Route exact path='/monthwisebargraph' element={<MonthWiseBarGraphContent />} />

          <Route exact path='/practiceexamqa/:qsl' element={<PracticeExamQA />} />

          <Route exact path='/liveexamqa/:qsl' element={<LiveExamQA />} />

        </Routes>
   {/* </BrowserRouter> */}

  {/* // <Routes>
  //   <Route path="/" exact component={Terms} />
  //   <Route path='/dashboard' exact component={Home} />
  //   <Route path='/login' exact component={Login} />
  //   <Route path='/forgotpassword' exact component={Forgotpassword} />
  //   <Route path='/upcomingliveexam' exact component={UpcomingLiveExam} />
  //   <Route path='/liveexam/:qsl' exact component={LiveExam} />
  //   <Route path='/upcomingpracticeexam' exact component={UpcomingPracticeExam} />
  //   <Route path='/profilesetting' exact component={Profilesetting} />
  //   <Route path='/startliveexam' exact component={StartLiveExam} />
  //   <Route path='/startpracticeexam' exact component={StartPracticeExam} />
  // </Routes> */}

    </>
  );
}

export default App;
