import React from 'react';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {useNavigate} from 'react-router-dom';
import { useParams } from 'react-router-dom';

// function createData(name, calories, fat, carbs, protein) {

// function createData(rank, name, mark) {
//     return { rank, name, mark };
//   }
  
//   const rows = [
//     createData(1, 'Ashok', 86.25),
//     createData(2, 'Ashok', 86.25),
//     createData(3, 'Ashok', 86.25),
//   ];

function LiveScoreViewContent() {
    let navigate = useNavigate();
    const params=useParams();

    let [livescoresetname, setLivescoresetname]=useState("");
    let [livescoreviewarr, setLivescoreviewarr]=useState([]);
    let [countstud, setCountstud]=useState(0);
    let [scoredate, setScoredate]=useState("");
    let [totalmark,setTotalmark]=useState("");

    let [rank, setRank]=useState("");

    // let [loader, setLoader]=useState(true);

    let i=0;
    let j=0;

    async function getLivescoreviewlist() {
        // alert(params.suid+' , '+params.livexmid);
        // alert(params.curdate+' , '+params.livexmid)
        var fd=new FormData();
        // fd.append("suid",params.suid);
        fd.append("curdate",params.curdate);
        fd.append("livexmid",params.livexmid);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getlivescoreviewlist_app",fd);
        var data=resp.data;
        console.log(data);
        setLivescoresetname(data.livesetname);
        setLivescoreviewarr(data.obj2);
        console.log("========>",data.obj2);
        setRank(data.rank);
        console.log(data.rank);
        setCountstud(data.studcount);
        setScoredate(data.studliveexamscore_date);
        setTotalmark(data.total_mark);

        // setLoader(false);
    }
      
    useEffect(()=>{
        getLivescoreviewlist();
    },[])


    return(
        <>
          <Container>
              <Grid container >
                  <Grid item xs={12}  pt={1}>
                      <Typography variant="h6" component="h6" pb={1.5} fontWeight="normal"> 
                        Exam : {livescoresetname}
                      </Typography>
                      <Typography variant="p" component="p" pb={1}> 
                        Total Students: <span>{countstud}</span>
                      </Typography>
                      <Typography variant="p" component="p" pb={1}> 
                        Live Score date: <span>{dateFormat(scoredate, "dd-mm-yyyy")}</span>
                      </Typography>
                      <Typography variant="p" component="p" pb={1}> 
                        Total Marks: <span>{totalmark}</span>
                      </Typography> 
                      

                      <Grid container direction="row" justifyContent="left" alignItems="left" pb={1}>         
                          <Grid justifyContent="left" p={1}><Button size="small" variant='contained' onClick={()=>{
                            let path = `/livescore`; 
                            navigate(path);
                          }}>Back</Button></Grid>
                          <Link href={"https://www.safalata.in/safalatalara/livescoredownload/"+dateFormat(scoredate, "yyyy-mm-dd")+"/"+params.livexmid}><Grid justifyContent="center" p={1}><Button size="small" variant='contained' color='secondary' onClick={()=>{
                            // window.print();
                            window.open("https://www.safalata.in/safalatalara/livescoredownload/"+dateFormat(scoredate, "yyyy-mm-dd")+"/"+params.livexmid,"_blank");
                          }}>Download</Button></Grid></Link>
                      </Grid>

                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell width={10} style={{fontSize: 'medium', width: '20%'}}>#Rank </TableCell>
                              <TableCell align="lefft" style={{fontSize: 'medium', width: '60%'}}>Name</TableCell>
                              {/* <TableCell align="lefft" style={{fontSize: 'medium', width: '20%'}}>Duration</TableCell> */}
                              <TableCell align="center" width={100} style={{fontSize: 'medium', width: '20%'}}>Marked</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {livescoreviewarr.map((e)=>
                            rank[j].stud_liveset_score===e.stud_liveset_score?
                              <TableRow key={e.liveset_slno} >
                                <TableCell component="th" scope="row" style={{width: '20%', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{i=i+1}</TableCell>
                                <TableCell align="left" style={{width: '60%', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{e.name} {rank[j].stud_liveset_score}</TableCell>
                                <TableCell align="center" style={{width: '20%', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{e.stud_liveset_score}</TableCell>
                              </TableRow>
                            : rank[j+1].stud_liveset_score===e.stud_liveset_score?
                              <TableRow  key={e.liveset_slno} >
                                <TableCell component="th" scope="row" style={{width: '20%', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{i=i+1}</TableCell>
                                <TableCell align="left" style={{width: '60%', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{e.name}</TableCell>
                                <TableCell align="center" style={{width: '20%', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{e.stud_liveset_score}</TableCell>
                              </TableRow>
                            : rank[j+2].stud_liveset_score===e.stud_liveset_score?
                              <TableRow  key={e.liveset_slno} >
                                <TableCell component="th" scope="row" style={{width: '20%', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{i=i+1}</TableCell>
                                <TableCell align="left" style={{width: '60%', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{e.name}</TableCell>
                                <TableCell align="center" style={{width: '20%', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{e.stud_liveset_score}</TableCell>
                              </TableRow>
                            :
                              <TableRow  key={e.liveset_slno} >
                                <TableCell component="th" scope="row" style={{fontSize: 'medium', width: '20%'}}>{i=i+1}</TableCell>
                                <TableCell align="left" style={{fontSize: 'medium', width: '60%'}}>{e.name}</TableCell>
                                <TableCell align="center" style={{fontSize: 'medium', width: '20%'}}>{e.stud_liveset_score}</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                  </Grid>

              </Grid>   
          </Container>
        </>
  );
}

export default LiveScoreViewContent;

 




