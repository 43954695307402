import React  from 'react';


// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";
import { Grid } from '@mui/material';
import LiveScoreContent from '../content/LiveScoreContent';



function LiveScore() {
    return(
        <>
        
        <Grid className='bg-greenlight'>
        <Grid className='home_bg'>
        <BackHeader /> 
        </Grid>

        <Grid py={2}>
        <LiveScoreContent />
        </Grid> 
        </Grid>
        </>
    );
}

export default LiveScore;