import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import StarBorder from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';

// import Grid from '@mui/material/Grid';

import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link } from "react-router-dom";


export default function Leftnav() {
  const navigate=useNavigate();
  const [open, setOpen] = React.useState(false);
  const [resultopen, setResultopen] = React.useState(false);
  const [jobopen, setJobopen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const resulthandleClick= () => {
    setResultopen(!resultopen);
  };

  const jobhandleClick= () => {
    setJobopen(!jobopen);
  };

  const editproileClick = () =>{
    const path=`../profilesetting`;
    navigate(path);
  };

  const proileimageClick = () =>{
    const path=`../fileupload`;
    navigate(path);
  };

  const changepasswordClick = () =>{
    const path=`../changepassword`;
    navigate(path);
  };

  const livescoreClick = () => {
    const path=`../livescore`;
    navigate(path);
  };

  const practicescoreClick = () => {
    const path=`../practicescore`;
    navigate(path);
  };

  const monthlyscoreClick = () => {
    const path=`../monthwisescore`;
    navigate(path);
  };

  const paymenthistoryClick = () =>{
    const path=`../paymenthistory`;
    navigate(path);
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader>
          <ListSubheader sx={{ pt: 1 }} component="div" id="nested-list-subheader">
            <Link to="/"><img src="../../images/logo.png" alt="" /></Link>
            <Box sx={{ pl: 1, fontWeight: 700 }} style={{ color: 'blue' }}>Hi, {localStorage.getItem("name").split(' ')[0]}</Box>
          </ListSubheader>

          {/* <ListSubheader sx={{ pl: 1, pt: 1 }} component="div" id="nested-list-subheader">
            Welcome To
          </ListSubheader> */}
        </ListSubheader>

      }
    >
    

     
      {/* <ListItemButton>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary="Sent mail" />
      </ListItemButton> */}
      
      {/* <ListItemButton>
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary="Drafts" />
      </ListItemButton> */}

      <ListItemButton onClick={handleClick} style={{ color: 'green' }}>
        <ListItemIcon>
          <Person3OutlinedIcon style={{ color: 'green' }} />
        </ListItemIcon>
        <ListItemText primary="Manage Profile" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={editproileClick} style={{ color: 'green' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Profile Settings" 
            />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={changepasswordClick} style={{ color: 'green' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Change Password" 
            />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={proileimageClick} style={{ color: 'green' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Update Profile Picture" 
            />
          </ListItemButton>
        </List>



      </Collapse>

      

    
      <ListItemButton onClick={resulthandleClick} style={{ color: 'maroon' }} >
        <ListItemIcon>
          <ContentPasteSearchOutlinedIcon style={{ color: 'maroon' }} />
        </ListItemIcon>
        <ListItemText primary="Result" />
        {resultopen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={resultopen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={livescoreClick} style={{ color: 'maroon' }} >          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: 'maroon' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Live Exam Result" 
            />
          </ListItemButton>
        </List>

        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={monthlyscoreClick} style={{ color: 'maroon' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: 'maroon' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Monthly Score Report" 
            />
          </ListItemButton>
        </List>

        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={practicescoreClick} style={{ color: 'maroon' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: 'maroon' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Practice Exam Result" 
            />
          </ListItemButton>
        </List>



      </Collapse>


      <ListItemButton onClick={paymenthistoryClick} style={{ color: 'blue' }}>
        <ListItemIcon>
          <CurrencyRupeeOutlinedIcon color='primary' />
        </ListItemIcon>
        <ListItemText primary="Payment History" />
      </ListItemButton>

      {/* Jobs */}

      {/* <ListItemButton onClick={jobhandleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Job" />
        {jobopen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={jobopen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={()=>{
              const path=`https://www.freejobalert.com/government-jobs/`;
              navigate(path);
            }} >          
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
            <ListItemText 
            primary="FreeJobAlert - Government Jobs" 
            />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={()=>{
              const path=`https://www.sarkariresult.com/`;
              navigate(path);
            }} >          
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
            <ListItemText 
            primary="Sarkari Results - Government Jobs" 
            />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={()=>{
              const path=`https://www.freshersworld.com/jobs/category/govt-sector-job-vacancies?src=fwhm`;
              navigate(path);
            }} >          
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
            <ListItemText 
            primary="Freshers World - Government Jobs" 
            />
          </ListItemButton>
        </List>



      </Collapse> */}
      

      <ListItemButton onClick={()=>{
        localStorage.clear();
        const path=`/login`;
        navigate(path);
      }} style={{ color: 'red' }}>
        <ListItemIcon>
          <LogoutOutlinedIcon style={{ color: 'red' }} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>


      <ListItemButton sx={{ top: 60 }}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Version : v2.1.0" />
      </ListItemButton>
      
{/* Today is Thursday, May 18, 2023

    Dashboard
    Manage My Profile
        Profile Settings
        Change Password
        Update Profile Picture
    Payment Section
        Payment History 
    Section Result
        Live Exam Result
        Month Wise Score Report
        Practice Exam Result

    Section Jobs
        FreeJobAlert - Government Jobs
        Sarkari Results - Government Jobs
        Freshers World - Government Jobs */}



    </List>
  );
}
