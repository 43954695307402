import React  from "react";

import Button from '@mui/material/Button';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';



function Banner() {

const homeBanner = {

        

        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
   
    return(
      <>
      <div className="bannerWrapper">
        <Carousel responsive={homeBanner} arrows={false} showDots={true} ssr={true} swipeable={true} draggable={true} transitionDuration={500} autoPlay={true} autoPlaySpeed={4000}>
          <Grid>
            <img src="../../images/banner1.jpg" alt="" sx={{ width: 1 }} />       
          </Grid> 
          <Grid>
            <img src="../../images/banner2.jpg" alt="" sx={{ width: 1 }} />       
          </Grid>
          <Grid>
            <img src="../../images/banner3.jpg" alt="" sx={{ width: 1 }} />       
          </Grid>
          <Grid>
            <img src="../../images/banner4.jpg" alt="" sx={{ width: 1 }} />       
          </Grid>
          <Grid>
            <img src="../../images/banner5.jpg" alt="" sx={{ width: 1 }} />       
          </Grid>
          <Grid>
            <img src="../../images/banner6.jpg" alt="" sx={{ width: 1 }} />       
          </Grid>
        </Carousel>
      </div>
      </>
  );
}

export default Banner;