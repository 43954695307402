import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';

export const getToken = createAsyncThunk(
    "getToken",
    async (args, { rejectWithValue }) => {
      try {
          var tokenval=localStorage.getItem("tokenID");
          if (tokenval!=null) {
              var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
                headers: {
                    "Content-Type":"application/json",
                    "Authorization":"Bearer "+tokenval
                }
              });
              var data=await resp.json();

              //var profilepic=data.pic;

              //return profilepic;
              
              return data;

              
              // setPic(data.pic);
              // localStorage.setItem("pic",data.pic);
          }
          //  else {
          //     let path = `/`; 
          //     navigate(path);
          // }
      } catch (err) {
          return rejectWithValue("Opps found an error", err.response.data);
          // let path = `/`; 
          // navigate(path);
      }
    }
);


export const BackHeaderSlice = createSlice({
  name: "getToken",
  initialState: {
      users: [],
      loading: false,
      error: null,
      // searchData: [],
  },
  extraReducers: {
      
      //get single user
      [getToken.pending]: (state) => {
        state.loading = true;
      },
      [getToken.fulfilled]: (state, action) => {
        state.loading = false;
        state.users = action.payload;
        // console.log("fulfilled action --->", action.payload)
      },
      [getToken.rejected]: (state) => {
        state.loading = false;
      },
      
  }
});
export default BackHeaderSlice.reducer;