import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useSelector,useDispatch } from 'react-redux';

// import { getToken } from "../IncSlice/BackHeaderSlice";
// import { getNoticeBoard } from '../IncSlice/NoticeboardSlice';
import { getSuccessStory } from '../IncSlice/SuccessStorySlice';
import { WidthFull } from '@mui/icons-material';

import Alert from '@mui/material/Alert';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function SuccessStoryContent() {
    
    let navigate = useNavigate();
    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }

    const dispatch = useDispatch();

    // const data=useSelector((state) => {
    //     return state.users;
    // });
    // const data2=useSelector((state) => {
    //     return state.notice;
    // });
    const data3=useSelector((state) => {
        return state.story;
    });

    // async function getReduxToken() {
    //     dispatch(getToken());
    // }

    // let [noticename, setNoticename]=useState("");
    // let [noticedatetime, setNoticedatetime]=useState("");

    // async function getReduxNoticeBoard() {
    //     dispatch(getNoticeBoard());
    //     setNoticename(data2.noticeboard.noticename);
    //     setNoticedatetime(data2.noticeboard.noticedatetime);
    // }

    let [successstory, setSuccessstory]=useState([]);
    async function getSuccessStoryFunc() {
        dispatch(getSuccessStory());

        setSuccessstory(data3.successall);
        console.log("=========>",successstory);
    }

    

    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    // let [oneqst,setOneqst]=useState("");

    // function modalaihandleOpen() {
    //     setOneqst("AI Feature Pending");
    //     handleOpen();
    // }


    useEffect(()=>{
        // getReduxToken();
        // getReduxNoticeBoard();
        getSuccessStoryFunc();
        // getNoticeBoard();
        getchecklogin();
    }, []);

    return(
        <>
            <Container>
                
            <Grid container >
                <Grid item xs={12} p={0} color="red">
                    <Alert  variant="filled" component="h5" pb={5} fontWeight="large" severity="success"  align="center" >Success All Candidates</Alert>
                </Grid>
                
                    {successstory.map((e)=>

                    <Typography sx={{ width: '100%' }} key={e.id}>
                    {/* key={e.id} */}
                    
                        {/* <Grid item sm={12} xs={12} pl={2} p={1} onClick={()=>{
                            let path = `/upcomingliveexam`;
                            navigate(path);
                            }}>
                            <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                            <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                            <Grid>{e.id}</Grid>                
                            </Button>     
                        </Grid> */}
                        {/* <img src={"https://safalata.in/safalatalara/uploadpic/"+e.image} sm={12} xs={12} pl={2} p={1} className="btnUser" /> */}
                        <Grid sm={12} xs={12} pl={2} p={1}>
                        <img src={"https://safalata.in/safalatalara/uploadpic/"+e.image} p={1} className="successUser" /> 
                        <Typography pl={6}><b>{e.name}</b><br/>
                        {e.designation}<br/>
                        {e.department}<br/>
                        {e.city!=null?
                         e.city                        
                        :
                        ''
                        }
                         </Typography>
                        </Grid>
                    
                        </Typography>
                    )}

                </Grid>
                
            </Container>

        </>
    );
}

export default SuccessStoryContent;

