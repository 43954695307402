import React, {useState, useEffect} from 'react';
import axios from 'axios';
// import validator from 'validator';
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

function LoginContent() {

    let navigate = useNavigate();

    let [phone,setPhone]=useState("");
    let [password,setPassword]=useState("");

    let [isbtn,setIsbtn]=useState(true);

    let errphone="Registered Phone Number Required";
    let errpassword="Password Required";

    let [iserrphone,setIsrrphone]=useState(false);
    let [iserrpassword,setIsrrpassword]=useState(false);

    let [alert,setAlert]=useState(false);
    let [msg,setMsg]=useState("");
    let [msgalert,setMsgalert]=useState("");
    let [msgcolor, setMsgcolor]=useState("");
    let [msgcode, setMsgcode]=useState("");
    
    async function studLogin() {
        setMsgcode("");
        var flag=1;
        if (phone.length<10) {
            flag=0;
            setIsrrphone(true);
        }
        if (password.length==0) {
            flag=0;
            setIsrrpassword(true);
        }
        if (flag==1) {
        
            var fd=new FormData();
            fd.append("phone",phone);
            fd.append("password",password);

            const response = await axios.post('https://safalata.in/safalatalara/api/studlogin_mobapp', fd);
            const data = response.data;

            setAlert(true);
            setMsg(data.msg);
            setMsgalert(data.alert);
            setMsgcode(data.msgcode);

            if (data.msgcode==1) {
                setMsgcolor('green');
            } else {
                setMsgcolor('red');
            }

            if (data.msgcode==1) {

                localStorage.setItem('tokenID',data.stoken);

                var tokenval=localStorage.getItem("tokenID");

                if (tokenval!=null) {

                    var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
                        headers: {
                        "Content-Type":"application/json",
                        "Authorization":"Bearer "+tokenval
                        }
                    });
                    var data2=await resp.json();
                    localStorage.setItem("enrollid", data2.enroll_id);
                    localStorage.setItem("name", data2.name);
                    localStorage.setItem("status", data2.status);
                    localStorage.setItem("batchid",data2.batch_slno);
                    localStorage.setItem("pic",data2.pic);
                    localStorage.setItem("usertoken",data2.usertoken);

                    setIsbtn(true);
                    let path = `/dashboard`; 
                    navigate(path);

                }

            }
            
        }
        
    }

    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }

    useEffect(()=>{
        getchecklogin();
    }, []);

    return (

        <>

        <Container className='terms_bg_login_new'>

        <Grid  textAlign="center">
            <img src="../../images/logo.svg" alt="" width={250} />      
            </Grid>

            <Grid container pb={2} px={3} >
            
            <Grid item xs={12} p={0} color="white">
                <Typography variant="p" component="p">Proceed with your</Typography>
                <Typography variant="h4" component="h4" pb={5} fontWeight="medium">Login</Typography>

                {/* {alert?<Typography variant="h4" component="h4" pb={5} align="center" fontWeight="medium" color={msgcolor}>{msg}</Typography>:''} */}
                {msgcode==1?
                <Typography pb={3}>
                <Alert variant="filled" severity="success">
                    {msg}
                </Alert>
                </Typography>
                :msgcode==2 || msgcode==3?
                <Typography pb={3}>
                <Alert variant="filled" severity="warning">
                    {msg}
                </Alert>
                </Typography>
                :''
                }


                <Typography variant="div" component="div" pb={3} >
                    <TextField id="standard-basic" label="Registered Mobile Number" variant="standard" sx={{ input: { size: 50, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setPhone(ev.target.value);
                        if (phone.length==10) {
                        setIsrrphone(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrphone?<Typography variant="div" component="div" pb={3} sx={{ color: 'error.main' }}>{errphone}</Typography>:''}
                </Typography>

                <Typography variant="div" component="div" pb={3} className = "whitetextfield" sx={{ fontSize: 'large', color: 'white' }}>
                    <TextField id="password" label="Password" type="password" sx={{ input: { size: 50, color: 'white' } }} variant="standard" fullWidth onChange={(ev)=>{
                        setPassword(ev.target.value);
                        if (password.length>=1) {
                        setIsrrpassword(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrpassword?<Typography variant="div" component="div" pb={3} sx={{ color: 'error.main' }}>{errpassword}</Typography>:''}
                </Typography>
                
                <Button variant="contained" fullWidth className='btn-green' onClick={studLogin}>Login</Button> 
                

                <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="" underline="none" className='whitexText'>
                    New Registration
                    </Link> <Button variant="contained" className='btn-green' onClick={()=>{
                        let path = `/register`;
                        navigate(path);
                    }}>Click Here</Button> 
                </Typography>


                <Typography variant="div" component="div" py={0} textAlign="center" >
                    <Link to="/forgotpassword" underline="none" className='whitexText'>
                    Forgot Password?
                    </Link>
                </Typography>

            </Grid>
            {/* <Grid sm={12} display="flex" justifyContent="center" alignItems="center"></Grid>  */}

            



                {/* <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                    <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                    <Grid>Live Exam</Grid>                
                    </Button>     
                </Grid>

                <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
                    <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
                    <Grid>Practice Exam</Grid>                
                    </Button>     
                </Grid> */}

            


                

                {/* <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>
                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>

                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid> */}

            </Grid>   
        </Container>
 
        </>
    );
}

export default LoginContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;