
// import axios from 'axios';

// import dateFormat from 'dateformat';

// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';


// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';

// // import Paper from '@mui/material/Paper';

// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// import { useSelector,useDispatch } from 'react-redux';
// import { getToken } from "../IncSlice/BackHeaderSlice";

// import React from "react";
// import { useState, useEffect } from "react";

import Typography from '@mui/material/Typography';
import * as React from "react";

import { useState } from "react";

import {useNavigate} from 'react-router-dom';

import axios from 'axios';


/* BAR GRAPH HEADER */
// import Paper from '@material-ui/core/Paper';
import Paper from '@mui/material/Paper';
import {
	ArgumentAxis,
	ValueAxis,
    Title,
	Chart,
	BarSeries,
} from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';
/* BAR GRAPH HEADER */

// import BackHeader from "../inc/BackHeader";
import { Grid } from '@mui/material';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Sample data
const rowdata = [
    { argument: 1, value: '30' },
    { argument: 2, value: '20' },
    { argument: 3, value: '10' },
    { argument: 4, value: '50' },
    { argument: 5, value: '60' },
    { argument: 6, value: '30' },
    { argument: 7, value: '20' },
    // { argument: 8, value: 70 },
    // { argument: 9, value: 50 },
    // { argument: 10, value: 60 },
    // { argument: 11, value: 60 },
    // { argument: 12, value: 20 },
    // { argument: 13, value: 10 },
    // { argument: 14, value: 50 },
    // { argument: 15, value: 60 },
    // { argument: 16, value: 30 },
    // { argument: 17, value: 20 },
    // { argument: 18, value: 70 },
    // { argument: 19, value: 50 },
    // { argument: 20, value: 60 },
    // { argument: 21, value: 60 },
    // { argument: 22, value: 20 },
    // { argument: 23, value: 98.5 },
    // { argument: 24, value: 50 },
    // { argument: 25, value: 60 },
    // { argument: 26, value: 30 },
    // { argument: 27, value: 20 },
    // { argument: 28, value: 70 },
    // { argument: 29, value: 50 },
    // { argument: 30, value: 0 },
    // { argument: 31, value: 0 },
    // { argument: 32, value: 0 },
];



// export default class MonthWiseBarGraphContent React.PureComponent {
export default class MonthWiseBarGraphContent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rowdata,
        };
    }

    render() {
        const { rowdata : alldata } = this.state;

        return(
                 
           <Paper>

            <Chart
            // data={rowdata}
            data={alldata}
            >
            <ArgumentAxis />
            <ValueAxis max={7} />

            <BarSeries valueField="value" argumentField="argument" />

            {/* <Typography mt={2} align="center">English Live Exam</Typography> */}
            <Animation />
            </Chart>

           </Paper>


        );
    }
}

// export default MonthWiseBarGraphContent;






