import React  from 'react';

import PracticeExamQAContent from "../content/PracticeExamQAContent";

// import BackHeader from '../inc/BackHeader';
import ExamBackHeader from '../inc/ExamBackHandler';

import { Grid } from '@mui/material';

function PracticeExamQA() {
    return(
        <>
        
        <Grid className='terms_bg '>
        <Grid className='home_bg'>
        {/* <BackHeader />  */}
        <ExamBackHeader/>
        </Grid>

        <Grid py={2}>
        <PracticeExamQAContent/>
        </Grid> 

        </Grid>
       
        </>
    );
}

export default PracticeExamQA;