import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getNoticeBoard = createAsyncThunk(
    "getNoticeBoard",
    async (args, { rejectWithValue }) => {
      try {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
        var data=resp.data;
        
        // console.log('axios--------->',data);

        return data;

      } catch (err) {
          return rejectWithValue("Opps found an error", err.response.data);
          // let path = `/`; 
          // navigate(path);
      }
    }
);


export const NoticeboardSlice = createSlice({
  name: "getNoticeBoard",
  initialState: {
      noticeboard: [],
      loading: false,
      error: null,
      // searchData: [],
  },
  extraReducers: {
      
      //get single user
      [getNoticeBoard.pending]: (state) => {
        state.loading = true;
      },
      [getNoticeBoard.fulfilled]: (state, action) => {
        state.loading = false;
        state.noticeboard = action.payload;
        // console.log("fulfilled action --->", action.payload)
      },
      [getNoticeBoard.rejected]: (state) => {
        state.loading = false;
      },
      
  }
});
export default NoticeboardSlice.reducer;