import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';

function QALoader(props) {
//   const { loading = false } = props;

  return (
    <div> 
      
      <Box sx={{ width: "40%" }}>
        <Skeleton animation="wave" />
      </Box>

      <Box sx={{ width: "60%" }}>
        <Skeleton animation={false} />
      </Box>

      <Box sx={{ width: "90%", marginTop: "20px" }}>
        {/* <Skeleton /> */}
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
        <Box sx={{ margin: 1 }}>        
            <Skeleton variant="circular" width="30px" height="30px" sx={{ marginBottom: 2}}>
              <Avatar />
            </Skeleton>
            <Skeleton variant="circular" width="30px" height="30px" sx={{ marginBottom: 2}}>
              <Avatar />
            </Skeleton>
            <Skeleton variant="circular" width="30px" height="30px" sx={{ marginBottom: 2}}>
              <Avatar />
            </Skeleton>
            <Skeleton variant="circular" width="30px" height="30px" sx={{ marginBottom: 2}}>
              <Avatar />
            </Skeleton>
        </Box>
        <Box sx={{ width: '70%' }}>
            <Skeleton width="100%" height="30px" sx={{ marginBottom: 2}}>
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height="30px" sx={{ marginBottom: 2}}>
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height="30px" sx={{ marginBottom: 2}}>
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height="30px" sx={{ marginBottom: 2}}>
              <Typography>.</Typography>
            </Skeleton>
        </Box>
      </Box>
      

        <Box sx={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "10px" }}>
            <Skeleton width="25%" height="60px" sx={{ margin: '5px'}}>
              <Button></Button>
            </Skeleton>
            <Skeleton width="25%" height="60px" sx={{  margin: '5px'}}>
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="25%" height="60px" sx={{  margin: '5px'}}>
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="25%" height="60px" sx={{  margin: '5px'}}>
              <Typography>.</Typography>
            </Skeleton>
        </Box>
    
 
        
    </div>
  );
}

export default QALoader;