import React  from 'react';

import ReactBarChart2Content from "../content/ReactBarChart2Content";

// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";
import { Grid } from '@mui/material';


function ReactBarChart2() {
    return(
        <>
        
        <Grid className='bg-greenlight-graph'>
        <Grid className='home_bg'>
        <BackHeader /> 
        </Grid>

        <Grid py={2}>
        {/* <ReactBarChart2Content/> */}
        <ReactBarChart2Content/>
        </Grid> 

        </Grid>

        
        </>
    );
}

export default ReactBarChart2;