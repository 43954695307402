

import * as React from 'react';

import { useState, useEffect } from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { Divider, List, ListItemText } from "@mui/material";

import dateFormat from 'dateformat';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

export default function PaymentHistoryContent() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let navigate = useNavigate();

    let [isbtn,setIsbtn]=useState(true);

    // let [studname,setStudname]=useState('');
    // let [enrollid,setEnrollid]=useState('');
    // let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }

    async function getBatchname() {
      // console.log(localStorage.getItem("pic"));
      // setLoader(true);
      var fd=new FormData();
      fd.append("batchid",localStorage.getItem("batchid"));
      var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
      var data2=resp2.data;
      setBatchname(data2);
      // setLoader(false);
    }

    let [pic,setPic]=useState(null);

    // if (localStorage.getItem("livesetid")==null) {
    //     window.location.href="/dashboard";
    // }

  //   async function getBatchname() {
  //     // console.log(localStorage.getItem("pic"));
  //     // setLoader(true);
  //     var fd=new FormData();
  //     fd.append("batchid",localStorage.getItem("batchid"));
  //     var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
  //     var data2=resp2.data;
  //     setBatchname(data2);
  //     setPic(localStorage.getItem("pic"));
  //     setStudname(localStorage.getItem("name"));
  //     setEnrollid(localStorage.getItem("enrollid"));
  //     setStudstatus(localStorage.getItem("status"));
  //     // setLoader(false);
  // }

  //PAYMENT LIST
  let i=0;
  let j=0;

  let [paidlistarr,setPaidlistarr]=useState([]);
  let [unpaidlistarr,setUnpaidlistarr]=useState([]);


  function getNostorage() {
    if (localStorage.getItem("livesetid")!=null) {
      localStorage.removeItem("livesetid");
    }
  }


  async function getpaymenthistory() {
      // alert(localStorage.getItem("enrollid"));
      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      var resp=await axios.post("https://safalata.in/safalatalara/api/mypaymenthistory",fd);
      var data=resp.data;
      setPaidlistarr(data.paidlist);
      setUnpaidlistarr(data.unpaidlist);
      // console.log()
  }

  useEffect(()=>{

    getReduxToken();

    getBatchname();
    getNostorage();
    getpaymenthistory();
  },[])

  return (
    <>
      <Container>
        <Grid container >
            <Grid item xs={12} p={1}>
            <Typography variant="h6" component="h6" pb={1.5} fontWeight="bold" color="red"> 
            {name}
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Batch Name: <span>{batchname}</span>
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Enrollment ID: <span>{enrollid}</span>
            </Typography>
            {status==1?
            <Typography variant="p" component="p" fontWeight="bold" color="#00630f" pb={1}> 
                Student Status: ACTIVE
            </Typography> 
            :
            <Typography variant="p" component="p" fontWeight="bold" color="#eb0520" pb={1}> 
                Student Status: INACTIVE
            </Typography>
            }

                  <Card>
                    <CardContent className='font14 p-0'>
                      <Box sx={{ width: '100%', typography: 'body1'  }}s >
                      <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab label="Unpaid List" value="1" />
                              <Tab label="Paid List" value="2" />
                          </TabList>
                          </Box>
                          <TabPanel value="1">
                          {unpaidlistarr.map((e)=>  
                          <Typography>
                            <Typography key={e.payment_month}> 
                              {e.payment_month}
                            </Typography>
                            <Divider sx={{ bgcolor: "blue", marginTop: 2, marginBottom: 2 }} />
                          </Typography>
                          )}
                          </TabPanel>
                          <TabPanel value="2">
                          {paidlistarr.map((e)=>
                          <Typography>
                            <Typography key={e.payment_month}> 
                              {e.payment_list+' = '+dateFormat(e.payment_date, "dd/mm/yyyy")}
                            </Typography>
                            <Divider sx={{ bgcolor: "green", marginTop: 2, marginBottom: 2 }} />
                          </Typography>
                          )}
                          </TabPanel>
                      </TabContext>
                      </Box>
                    </CardContent>
                  </Card>

            </Grid>

        </Grid>   
    </Container>

    </>
  );
}