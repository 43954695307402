import React  from 'react';

import UpdatePasswordContent from "../content/UpdatePasswordContent";

function UpdatePassword() {

    
    return(
        <>

        <UpdatePasswordContent/>
       
        </>
    );
}

export default UpdatePassword;