import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


// import { createTheme } from '@mui/material/styles';


import { useSelector,useDispatch } from 'react-redux';

import { getToken } from "../IncSlice/BackHeaderSlice";

import { getNoticeBoard } from '../IncSlice/NoticeboardSlice';

import { getSuccessStory } from '../IncSlice/SuccessStorySlice';

import axios from 'axios';

import dateFormat from 'dateformat';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function IndexContent() {
    
    let navigate = useNavigate();
   
    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }
    

    const dispatch = useDispatch();

    const data=useSelector((state) => {
        return state.users;
    });
    const data2=useSelector((state) => {
        return state.notice;
    });

    const data3=useSelector((state) => {
        return state.story;
    });

    async function getReduxToken() {
        dispatch(getToken());
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getReduxNoticeBoard() {
        dispatch(getNoticeBoard());
        setNoticename(data2.noticeboard.noticename);
        setNoticedatetime(data2.noticeboard.noticedatetime);
        // console.log('print--------->', data2.noticeboard.noticename);
    }

    async function getSuccessStoryFunc() {
        dispatch(getSuccessStory());
        // console.log("=========>",successstory);
    }

    // async function getNoticeBoard() {
    //     var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
    //     var data=resp.data;

    //     setNoticename(data.noticename);
    //     setNoticedatetime(data.noticedatetime);
    // }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [oneqst,setOneqst]=useState("");

    function modalaihandleOpen() {
        setOneqst("AI ChatGPT Pending");
        handleOpen();
    }


    useEffect(()=>{
        getReduxToken();
        getReduxNoticeBoard();
        getSuccessStoryFunc();
        getNoticeBoard();
        getchecklogin();
    }, []);

    return(
        <>
            <Container>
                <Grid container >
                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        //let path = `/liveexamlist`;
                        let path = `/upcomingliveexam`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                        <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                        <Grid>Live Exam</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/upcomingpracticeexam`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
                        <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
                        <Grid>Practice Exam</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6}  p={1} onClick={()=>{
                        let path = `/livescore`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-green-dashboard'>            
                        <Grid><img src="../../images/live-score.png" alt="" /></Grid> 
                        <Grid>Live Score</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/practicescore`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-pink'>            
                        <Grid><img src="../../images/practice-score.png" alt="" /></Grid> 
                        <Grid>Practice Score</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/monthwisescore`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-redlight'>            
                        <Grid><img src="../../images/monthwise-score.png" alt="" /></Grid> 
                        <Grid>Monthly score</Grid>                
                        </Button>
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/paymenthistory`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-bluelight'>            
                        <Grid><img src="../../images/pyment-history.png" alt="" /></Grid> 
                        <Grid>Payment History</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/successstory`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-redlight-story'>            
                        <Grid><img src="../../images/success-story.png" alt="" /></Grid> 
                        <Grid>Success Story</Grid>                
                        </Button>     
                    </Grid>

                    {/* <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/viewchart`;
                        navigate(path);
                        }}> */}
                    <Grid item sm={6} xs={6} p={1} onClick={modalaihandleOpen}>
                    {/* onClick={modalaihandleOpen} */}
                        <Button variant="contained" fullWidth className='hbtn btn-bluelight-ai'>            
                        <Grid><img src="../../images/ai_chatgpt.png" alt="" /></Grid> 
                        <Grid>AI ChatGPT</Grid>                
                        </Button>     
                    </Grid>

                    {/* <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>
                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>

                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid> */}

                    <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                        
                        {/* <Grid item> */}
                            <Typography color={'#581d1d'} fontWeight={'bold'}> 
                                {noticename}  
                            </Typography> 

                            <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                                Notice Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}
                            </Typography> 
                        {/* </Grid> */}

                    </Grid>

                </Grid>   


                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    
                    <Typography id="modal-modal-description" className="text-red" sx={{ mt: 1, mb: 2 }}>
                        {oneqst}
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>

                
            </Container>

        </>
    );
}

export default IndexContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;