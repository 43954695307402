import React, { useState, useEffect } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';



import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import { Link } from "react-router-dom";

import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';

import Leftnav from "../inc/Leftnav";

import {useNavigate} from 'react-router-dom';

import axios from "axios";


const Header = ({ variant, ...props }) => {
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  async function getCheckToken() {

      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      var resp=await axios.post("https://safalata.in/safalatalara/api/checkusertoken",fd);
      var data=resp.data;
      if (data.usertoken!=localStorage.getItem('usertoken')) {
        localStorage.clear();
        let path = `/login`; 
        navigate(path);
      }

      // console.log('db session : '+data.usertoken+' , session : '+localStorage.getItem('usertoken'))

  }

  useEffect(()=>{
    getCheckToken();
  },[]);

  return (
    <>

    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" color="transparent" className="navbar">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}  alignItems="center">
            News
          </Typography> */}
          <Grid display="flex" sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
          <img src="../images/logo.png" />
        </Grid>
          <Button variant="contained"  startIcon={<DehazeOutlinedIcon style={{ color: 'white' }} />}  onClick={() => setOpen(!open)} className="btnRounded btn" ></Button>
        </Toolbar>
      </AppBar>
    </Box>




    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Drawer
          variant={variant}
          {...props}
          open={open}
          onClose={() => setOpen(false)}  className="drawerBox"
        >

           
            <Leftnav />

          {/* <List>
            <ListItem button onClick={() => setOpen(false)}>
              <ListItemText>
                <Link style={{ textDecoration: "none" }} to="/">
                  Home
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem button onClick={() => setOpen(false)}>
              <ListItemText>
                <Link style={{ textDecoration: "none" }} to="/about">
                  About Us
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem button onClick={() => setOpen(false)}>
              <ListItemText>
                <Link style={{ textDecoration: "none" }} to="/contact">
                  Contact Us
                </Link>
              </ListItemText>
            </ListItem>
          </List> */}
        </Drawer>
      </Grid>

      
    </Grid>
    </>

  );
};

export default Header;