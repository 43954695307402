import React  from 'react';

import SuccessStoryContent from "../content/SuccessStoryContent";

// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";
// import Banner from "../inc/Banner";

import { Grid } from '@mui/material';



function SuccessStory() {
    return(
        <>
        <Grid className='bg-successstory'>

        <Grid className='home_bg'>
        <BackHeader />
        </Grid>

         <Grid py={2}>
        <SuccessStoryContent/>
        </Grid>  

        </Grid>
        </>
    );
}

export default SuccessStory;