import React  from 'react';

import StartLiveExamContent from "../content/StartLiveExamContent";

// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";
import { Grid } from '@mui/material';


function StartLiveExam() {
    return(
        <>
        
        <Grid className='bg-greenlight'>
        <Grid className='home_bg'>
        <BackHeader /> 
        </Grid>

        <Grid py={2}>
        <StartLiveExamContent/>
        </Grid> 

        </Grid>

        
        </>
    );
}

export default StartLiveExam;