import React  from 'react';


// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";
import { Grid } from '@mui/material';
import LiveScoreViewContent from '../content/LiveScoreViewContent';


function LiveScoreView() {
    return(
        <>
        
        <Grid className='bg-greenlight'>
        <Grid className='home_bg'>
        <BackHeader /> 
        </Grid>

        <Grid py={2}>
        <LiveScoreViewContent/>
        </Grid> 
        </Grid>
        </>
    );
}

export default LiveScoreView;