import React  from 'react';

import TermsContent from "../content/TermsContent";


function Profilesetting() {
    return(
        <>
        <TermsContent/>
        </>
    );
}

export default Profilesetting;