import React  from 'react';

import LiveExamContent from "../content/LiveExamContent";

// import BackHeader from "../inc/BackHeader";
import ExamBackHeader from '../inc/ExamBackHandler';

import { Grid } from '@mui/material';



function LiveExam() {
    return(
        <>
        
        <Grid className='terms_bg '>
        <Grid className='home_bg'>
        {/* <BackHeader />  */}
        <ExamBackHeader />
        </Grid>

        <Grid py={2}>
        <LiveExamContent/>
        </Grid> 

        </Grid>

        
        







       
        </>
    );
}

export default LiveExam;