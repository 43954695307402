import React  from 'react';

import LiveExamContentQA from "../content/LiveExamContentQA";

// import BackHeader from "../inc/BackHeader";
import ExamBackHeader from '../inc/ExamBackHandler';

import { Grid } from '@mui/material';


function LiveExamQA() {
    return(
        <>
        
        <Grid className='terms_bg '>
        <Grid className='home_bg'>
        {/* <BackHeader />  */}
        <ExamBackHeader />
        </Grid>

        <Grid py={2}>
        <LiveExamContentQA/>
        </Grid> 

        </Grid>

        
       
        </>
    );
}

export default LiveExamQA;