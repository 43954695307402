import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';
import {useNavigate} from 'react-router-dom';

import dateFormat from 'dateformat';

import axios from 'axios';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import EXAMLISTLoader from './EXAMLISTLoader';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function UpcomingPracticeExamContent() {
    
    let navigate = useNavigate();

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }

    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }

    let [batchname,setBatchname]=useState('');

    async function getBatchname() {
        // console.log(localStorage.getItem("pic"));
        // setLoader(true);
        var fd=new FormData();
        fd.append("batchid",localStorage.getItem("batchid"));
        var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);
        // setLoader(false);
    }

    //UPCOMING PRACTICE EXAM LIST
    let [upcomingpracticearr,setUpcomingpracticearr]=useState([]);
    let [practicecount,setPracticecount]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    let [loader,setLoader]=useState(false);

    async function upcomingpracticeexam() {
      // console.log(enrollid);
      // console.log(curdate);
      // let enrollid=localStorage.getItem("enrollid");
    
      setLoader(true);

      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      fd.append("curdate",curdate);
      var resp=await axios.post("https://safalata.in/safalatalara/api/upcomingpracticeexam",fd);
      var data=resp.data;
      setUpcomingpracticearr(data);
      setPracticecount(data.practiceexamcount);

      setLoader(false);
    }

    let [orderstatus, setOrderstatus]=useState("");
    async function getstudlogincheck() {
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.in/safalatalara/api/studlogincheck",fd);
        var data=resp.data;
        setOrderstatus(data);
        // console.log(data);
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [oneqst,setOneqst]=useState("");

    function modalexamdonehandleOpen() {
        setOneqst("Exam Already Done");
        handleOpen();
    }

    function modalexamsoonhandleOpen() {
        setOneqst("Exam Soon");
        handleOpen();
    }

    useEffect(()=>{
        getReduxToken();

        getchecklogin();

        getBatchname();
        upcomingpracticeexam();

        getstudlogincheck();

    }, []);

    return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12}  p={1}>
                    <Typography variant="h6" component="h6" pb={1.5} fontWeight="bold" color="red"> 
                            {name}
                        </Typography>
                        <Typography variant="p" component="p" pb={1}> 
                            Batch Name: <span>{batchname}</span>
                        </Typography>
                        <Typography variant="p" component="p" pb={1}> 
                            Enrollment ID: <span>{enrollid}</span>
                        </Typography>
                        {status==1?
                        <Typography variant="p" component="p" fontWeight="bold" color="#00630f" pb={1}> 
                            Student Status: ACTIVE
                        </Typography> 
                        :
                        <Typography variant="p" component="p" fontWeight="bold" color="#eb0520" pb={1}> 
                            Student Status: INACTIVE
                        </Typography>
                        }
                        

                        <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                        Upcoming Practice Exam
                        </Typography>

                        <Card>

                        {!loader?
                        <CardContent>
                        {orderstatus==1?
                            upcomingpracticearr.map((e)=>
                            <Typography key={e.practiceset_slno}>
                                <Typography variant="h6" component="div" mb={1}  color="maroon" >
                                {e.practiceset_name}
                                </Typography>
                                <Typography mb={1} color="text.secondary">
                                Date: {dateFormat(e.practiceexamsetselection_start_schedule, "dd-mm-yyyy")}
                                </Typography>
                                <Typography variant="body2"  mb={1} >
                                Time: {dateFormat(e.practiceexamsetselection_start_schedule, "HH:MM TT")}-{dateFormat(e.practiceexamsetselection_end_schedule, "HH:MM TT")}
                                </Typography>
                                {(new Date(dateFormat(e.practiceexamsetselection_start_schedule, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    // e.existscorecount==0?
                                    <Button size="small" variant='contained' onClick={()=>{
                                        localStorage.setItem("practicesetid",e.practiceset_slno);
                                        let path = `/startpracticeexam`; 
                                        navigate(path);
                                    }}>Go Exam</Button> 
                                    // :
                                    // <Button size="small" variant='contained'>Exam Done</Button>
                                :
                                <Button size="small" variant='contained'>Exam Soon</Button>
                                }
                                <Grid py={2}>
                                <Divider  />
                                </Grid>
                            </Typography>
                            )
                        :
                        ''
                        }
                        </CardContent>
                        :
                        <EXAMLISTLoader />
                        }
                        
                        </Card>


                    

                        

                        
                        

                    </Grid>




                    

                    {/* <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>
                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>

                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid> */}

                </Grid>   

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    
                    <Typography id="modal-modal-description" className="text-red" sx={{ mt: 1, mb: 2 }}>
                        {oneqst}
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>


            </Container>

        </>
    );
}

export default UpcomingPracticeExamContent;






