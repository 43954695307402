import React  from 'react';

import RegisterContent from "../content/RegisterContent";


function Register() {
    return(
        <>
        <RegisterContent/>
        </>
    );
}

export default Register;