import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';

export default function EXAMLISTLoader() {
  return (
    <Box sx={{ paddingLeft: '10px',paddingRight: '10px',width: "95%" }}>
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="rectangular" width={190} height={40} />
      <Skeleton variant="text" sx={{ fontSize: '0.3rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="rectangular" width={190} height={40} />
      <Skeleton variant="text" sx={{ fontSize: '0.3rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="rectangular" width={190} height={40} />n
      <Skeleton variant="text" sx={{ fontSize: '0.3rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: '50%' }} />
      <Skeleton variant="rectangular" width={190} height={40} />
      <Skeleton variant="text" sx={{ fontSize: '0.3rem' }} />
    </Stack>
    </Box>
  );
}