import React  from 'react';

import LoginContent from "../content/LoginContent";


function Login() {
    return(
        <>
        <LoginContent/>
        </>
    );
}

export default Login;