import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {useNavigate} from "react-router-dom";

function FileUploadContent() {

    let navigate = useNavigate();

    if (localStorage.getItem("tokenID")==null) {
        window.location.href="/login";
     }
  
     function getNostorage() {
        if (localStorage.getItem("livesetid")!=null) {
          localStorage.removeItem("livesetid");
        }
     }
  
     let [msg,setMsg]=useState("");
     let [msgcode,setMsgcode]=useState("");
     let [msgcolor,setMsgcolor]=useState("");
  
     let [pic,setPic]=useState(null);
     let [newpic,setNewpic]=useState(null);
  
     let [isbtn,setIsbtn]=useState(true);
     
     async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
           var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
              headers: {
                 "Content-Type":"application/json",
                 "Authorization":"Bearer "+tokenval
              }
           });
           var data=await resp.json();
           setPic(data.pic);
           localStorage.setItem("pic",data.pic);
           } else {
                let path = `/`; 
                navigate(path);
           }
  
     }
  
     
     
     async function picupdate() {
        
        // if (flag==1) {
           alert(newpic.name)
           var fd=new FormData();
  
           fd.append("pic",newpic);
           fd.append("enrollid",localStorage.getItem("enrollid"));
           var resp=await axios.post("https://safalata.in/safalatalara/api/picupdate",fd);
           var data=resp.data;
           setMsg(data.msg);
           setMsgcode(data.msgcode);
           setMsgcolor(data.msgcolor);
  
           if (data.msgcode==1) {
               setIsbtn(true);
  
               window.scrollTo({
                   top: 0,
                   behavior: "smooth"
               });
               getToken();
           }
        // }
     }
  
     useEffect(()=>{
        getToken();
        getNostorage();
     },[])

    return(
        <>

        <Container>
            <Grid container py={3} >
                <Typography variant="h6" component="h6" pb={1} fontWeight="normal">Profile Update Picture</Typography>
                <img src={"https://safalata.in/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{width: '200px'}} />
            </Grid>

            <Grid container pb={2} px={3} >
                <Grid item xs={12}  p={0} color="white">
                    

                    <Typography variant="div" component="div" pb={3}>
                    <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(ev)=>{
                        setNewpic(ev.target.files[0]);
                        // alert(ev.target.files[0].name);
                        if (ev.target.files[0].name!=null) {
                        setIsbtn(false);
                        } else {
                        setIsbtn(true);
                        }
                    }} accept=".jpg,.jpeg"/>
                    </Typography>

                    

                    <Button variant="contained" mb={3} disabled={isbtn} fullWidth className='btn-green' onClick={picupdate}>            
                    Update Profile Image
                    </Button> 

                    {/* <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="/login" underline="none" className='whitexText'>
                        Login
                    </Link>
                    </Typography> */}
                

                </Grid>
            </Grid>

            
        </Container>

        </>
    );
}

export default FileUploadContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;