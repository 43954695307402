import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import dateFormat from 'dateformat';

import axios from 'axios';

import {useNavigate} from 'react-router-dom';

function StartPracticeExamContent() {
    let navigate = useNavigate();

    let [isbtn,setIsbtn]=useState(true);

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');

    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [pic,setPic]=useState(null);

    if (localStorage.getItem("practicesetid")==null) {
        window.location.href="/dashboard";
    }

    async function getToken() {
      setLoader(true);

      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
        var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
          headers: {
            "Content-Type":"application/json",
            "Authorization":"Bearer "+tokenval
          }
        });
        var data=await resp.json();
        setStudname(data.name);
        setEnrollid(data.enroll_id);
        setStudstatus(data.status);
        setPic(data.pic);

        if (data.name.length>1 && localStorage.getItem("enrollid")==null) {
          localStorage.setItem("enrollid",data.enroll_id);
        }

        var fd=new FormData();
        fd.append("batchid",data.batch_slno);
        var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);

        setLoader(false);

      } else {
        // window.location.href="/";
        let path = `/`;
        navigate(path);
      }

    }

    
    //PRACTICE EXAM DETAILS
    let [examname,setExamname]=useState("");
    let [examtotalqst,setExamtotalqst]=useState("");
    let [examstdate,setExamstdate]=useState("");
    let [exameddate,setExameddate]=useState("");

    async function practiceexamdetails() {
      let practicesetid=localStorage.getItem("practicesetid");
      var fd=new FormData();
      fd.append("practicesetid",practicesetid);
      var resp=await axios.post("https://safalata.in/safalatalara/api/practiceexamdetails",fd);
      var data=resp.data;
      setExamname(data.practicesetname);
      setExamstdate(data.stdate);
      setExameddate(data.eddate);
      setExamtotalqst(data.qcount);

      localStorage.setItem("examname",data.practicesetname);
      localStorage.setItem("exameddate",data.eddate);
      localStorage.setItem("qcount",data.qcount);

      localStorage.setItem("shuffle",data.shuffle);
    }

    useEffect(()=>{
      getToken();
      practiceexamdetails();

    },[])

     return(


        <>

<Container>
    <Grid container >
        <Grid item xs={12}  p={1}>
        <Typography variant="h6" component="h6" pb={1.5} fontWeight="normal"> 
                {studname}
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Batch Name: <span>{batchname}</span>
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Enrollment ID: <span>{enrollid}</span>
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Student Status: {localStorage.getItem("status")==1?<span>Active</span>:<span>InActive</span>}
            </Typography> 

            <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
            Start Practice Exam
            </Typography>

            <Card className='borderRadius10'>
            <CardContent>
                <Typography variant="h6" component="div" mb={2} textAlign="left" className='text-green lineheight'>
                Go To Test Your <br />Practice Online Exam
                </Typography>
                <Typography mb={1}>
                This is Multiple Choice Practice Set To Test Your Knowledge
                </Typography>
                <Typography variant="body2"  mb={1} >
                practice Exam Set Name : {examname}
                </Typography>

                <Typography variant="body2"  mb={1} >
                Number of Questions : {examtotalqst}
                </Typography>
                <Typography variant="body2"  mb={1} >
                Exam Date & Time : {dateFormat(examstdate, "dd-mm-yyyy ( HH:MM TT")} - {dateFormat(exameddate, "HH:MM TT )")}
                </Typography>
                <Typography variant="body2"  mb={1} >
                Question Type : Multiple Choice
                </Typography>

                                       
                

            </CardContent>
            
            </Card >


             

        </Grid>

        <Grid item xs={12} p={1}>
            <Button fullWidth variant='contained' className='btn-green' onClick={async()=>{
                let practicesetid=localStorage.getItem("practicesetid");
                let enrollid=localStorage.getItem("enrollid");
                var fd=new FormData();
                fd.append("enrollid",enrollid);
                fd.append("practicesetid",practicesetid);
                var resp=await axios.post("https://safalata.in/safalatalara/api/clearpracticeexamsheet",fd);
                var data=resp.data;
                var page=data.page;
                // let path = `/practiceexam/`+page;
                let path = `/practiceexamqa/`+page;
                navigate(path);
            }}>Start Exam</Button>
        </Grid>


        

        {/* <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid>
        <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid>

        <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid> */}

    </Grid>   
</Container>

        </>
    );
}

export default StartPracticeExamContent;






