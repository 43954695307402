import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { getNoticeBoard } from '../IncSlice/NoticeboardSlice';

import { useSelector,useDispatch } from 'react-redux';


function TermsContent() {

    let navigate = useNavigate();

    function getchecklogin() {
        if (localStorage.getItem("tokenID")!=null) {
            let path = `/dashboard`; 
            navigate(path);
        }
    }

    const dispatch = useDispatch();

    const data2=useSelector((state) => {
        return state.notice;
    });

    async function getReduxNoticeBoard() {
        dispatch(getNoticeBoard());
    }
    

    useEffect(()=>{
        getchecklogin();
        getReduxNoticeBoard();
    }, []);

    return(
        <>
    
     
<Container className='terms_bg'>

<Grid  textAlign="center">
    <img src="../../images/logo.svg" alt="" width={250} />      
    </Grid>




    <Grid container pb={2} >
    <Grid item xs={12}  p={0} textAlign="center1" color="white">
            <Typography variant="h6" component="h6"pb={1} textAlign="center" >PREPARATION FOR</Typography>
            <Typography variant="p" component="p" pb={1}>Staff Selection Commission (Junior Hindi Translator/ Senior Hindi Translator/ Junior Translation Officer/Senior Translation Officer)</Typography>
            <Typography variant="p" component="p" pb={1}>Institute of Banking Personnel Selection (IBPS), Specialist Officer(SO)- Rajbhasha Adhikari</Typography>
            <Typography variant="p" component="p" pb={1}>Railway Recruitment Board(RRB) - Hindi Translator/ JTO</Typography>
            <Typography variant="p" component="p" pb={1}>Food Corporation of India - Assistant Grade - II (Hindi)</Typography>
            <Typography variant="p" component="p" pb={1}>Rajbhasha Adhikari/ Official Language Officer/ Senior Translation Officer/ Junior Translation Officer/Senior Hindi Translator (SHT)/ Junior Hindi Translator (JHT), Hindi Assistant posts in Loksabha, Rajsabha, RBI, NABARD, all PSUs, Central Govt Departments, National Educational Institutes, Autonomous Bodies</Typography>


            <Typography variant="h6" component="h6"pb={1} pt={2} textAlign="center">ELIGIBILITY CRITERIA</Typography>
            <Typography variant="p" component="p" pb={1}>Master's degree in Hindi with English as an elective subject or as the medium of examination at the degree level. OR</Typography>
            <Typography variant="p" component="p" pb={1}>Master's degree in English with Hindi as an elective subject or as the medium of examination at the degree level.</Typography>
            <Typography variant="p" component="p" pb={1}>Course Duration - 1 Year</Typography>
            <Typography variant="p" component="p" pb={1}>Courses Commence in the month of January and July Every Year</Typography>
            <Typography variant="p" component="p" pb={1}>
            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked className='whitechecked' />} label="I have read all the above instructions" />
            </FormGroup>
            </Typography>
            <Button variant="contained" fullWidth className='btn-green' onClick={()=>{
                    let path = `/login`;
                    navigate(path); 
            }}>            
                Go To My Dashboard              
            </Button> 
       

    </Grid>
    {/* <Grid sm={12} display="flex" justifyContent="center" alignItems="center"></Grid>  */}

    



        {/* <Grid item sm={6} xs={6}  p={1}>
            <Button variant="contained" fullWidth className='hbtn btn-blue'>            
            <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
            <Grid>Live Exam</Grid>                
            </Button>     
        </Grid>

        <Grid item sm={6} xs={6}  p={1}>
            <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
            <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
            <Grid>Practice Exam</Grid>                
            </Button>     
        </Grid> */}

       


        

        {/* <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid>
        <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid>

        <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid> */}

    </Grid>   
</Container>
 
        </>
    );
}

export default TermsContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;