import React  from 'react';

import ProfileSettingContent from "../content/ProfileSettingContent";

// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";


function ProfileSetting() {

    
    return(
        <>
        <div className='home_bg'>
        <BackHeader />
        </div> 

        <ProfileSettingContent/>
       
        </>
    );
}

export default ProfileSetting;