import React from "react";
// import Paper from '@material-ui/core/Paper';
import Paper from '@mui/material/Paper';
import {
	ArgumentAxis,
	ValueAxis,
	Chart,
	BarSeries,
} from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';

const ViewChart = () => {

	// Sample data
	const data = [
		{ argument: 1, value: 30 },
		{ argument: 2, value: 20 },
		{ argument: 3, value: 10 },
		{ argument: 4, value: 50 },
		{ argument: 5, value: 60 },
		{ argument: 6, value: 30 },
		{ argument: 7, value: 20 },
		{ argument: 8, value: 70 },
		{ argument: 9, value: 50 },
		{ argument: 10, value: 60 },
		{ argument: 11, value: 60 },
		{ argument: 12, value: 20 },
		{ argument: 13, value: 10 },
		{ argument: 14, value: 50 },
		{ argument: 15, value: 60 },
		{ argument: 16, value: 30 },
		{ argument: 17, value: 20 },
		{ argument: 18, value: 70 },
		{ argument: 19, value: 50 },
		{ argument: 20, value: 60 },
		{ argument: 21, value: 60 },
		{ argument: 22, value: 20 },
		{ argument: 23, value: 98.5 },
		{ argument: 24, value: 50 },
		{ argument: 25, value: 60 },
		{ argument: 26, value: 30 },
		{ argument: 27, value: 20 },
		{ argument: 28, value: 70 },
		{ argument: 29, value: 50 },
		{ argument: 30, value: 0 },
		{ argument: 31, value: 0 },
		{ argument: 32, value: 0 },
	];
	return (
		<Paper>
			<Chart
				data={data}
			>
				<ArgumentAxis />
				<ValueAxis max={32} />

				<BarSeries valueField="value" argumentField="argument" />
				{/* <Title text="World population" /> */}
         		<Animation />
			</Chart>
		</Paper>
	);
}

export default ViewChart;
