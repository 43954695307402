import React  from 'react';

import ForgotPasswordContent from "../content/ForgotPasswordContent";


function ForgotPassword() {
    return(
        <>
        <ForgotPasswordContent/>
        </>
    );
}

export default ForgotPassword;