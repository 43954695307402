// import React from 'react';
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import axios from 'axios';

import dateFormat from 'dateformat';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';

import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import {useNavigate} from 'react-router-dom';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// import faker from 'faker';
// import * as faker from 'faker';

// import * as faker from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



export default function ReactBarChart2Content() { 

    const options = {
        responsive: true,
        plugins: {
          legend: {
          //   position: 'top' as const,
          position: 'top',
          },
          title: {
            display: true,
            text: 'Live Exam Bar Chart',
          },
        },
      };


      let [hindidatadays,setHindidatadays]=useState([]);
      let [englishdatadays,setEnglishdatadays]=useState([]);
      let [hindiscore,setHindiscore]=useState([]);
      let [englishscore,setEnglishscore]=useState([]);


      
      //const labels = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10','11','12','13','14','15','16', '17', '18', '19', '20', '21', '22', '23', '24', '25','26','27','28','29','30','31'];
      const labels = hindidatadays;

      

      // const data = {
      //   labels,
      //   datasets: [
      //     {
      //       label: 'Hindi Exam Chart',
      //       //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //       data: [20,10,50,40,70,65,79,70,65,79,65,79,70,65,79,20,10,50,40,70,65,79,70,65,79,65,79,70,65,79,79],
      //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
      //     },
      //     {
      //       label: 'English Exam Chart',
      //       //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //       data: [20,10,50,40,98.5,65,79,70,65,79,65,79,70,65,79,20,10,50,40,70,65,79,70,65,79,65,79,70,65,79,79],
      //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
      //     },
      //   ],
      // };

      const data1 = {
        labels,
        datasets: [
          {
            label: 'Hindi Exam Chart',
            //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            //data: [20,10,50,40,70,65,79,70,65,79,65,79,70,65,79,20,10,50,40,70,65,79,70,65,79,65,79,70,65,79,79],
            data: hindiscore,
            backgroundColor: 'rgba(255, 99, 132, 0.9)',
          },
          
        ],
      };

      const labels2 = englishdatadays;

      const data2 = {
        labels,
        datasets: [
          
          {
            label: 'English Exam Chart',
            //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            //data: [20,10,50,40,98.5,65,79,70,65,79,65,79,70,65,79,20,10,50,40,70,65,79,70,65,79,65,79,70,65,79,79],
            data: englishscore,
            backgroundColor: 'rgba(53, 162, 235, 0.9)',
          },
        ],
      };

    let [yearmonth,setYearmonth]=useState("");
          //LIVE EXAM SCORE LIST
    let [livescorearr,setLivescorearr]=useState([]);
    let [monthyeararr,setMonthyeararr]=useState([]);

    

    let [days,setDays]=useState("");
    
      async function getstudscoremonthyear() {
          // alert(localStorage.getItem("enrollid"));
          var fd=new FormData();
          fd.append("enrollid",localStorage.getItem("enrollid"));
          var resp=await axios.post("https://safalata.in/safalatalara/api/getstudscoremonthyear",fd);
          var data=resp.data;
          setMonthyeararr(data);
          // console.log()
      }

    useEffect(()=>{
      getstudscoremonthyear();
    },[])
    return (
    <>
        <Paper>
          <Grid item p={1} container direction="row" justifyContent="center" alignItems="center" >
        
            <FormControl variant="standard" fullWidth>
              <InputLabel id="demo-simple-select-standard-label">Select Month</InputLabel>
              <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={yearmonth}
              label="Select Month"
              onChange={async(ev)=>{
                if (ev.target.value.length>0) {
                    var fd=new FormData();
                    fd.append("enrollid",localStorage.getItem("enrollid"));
                    fd.append("yearmonth",ev.target.value); 
                    var resp=await axios.post("https://safalata.in/safalatalara/api/livescorelistbyyearmonth_hindi_graph",fd);
                    var data=resp.data;
                    // setLivescorearr(data.englishobj);
                    setYearmonth(ev.target.value);
                    // setDays(data.days);

                    setHindidatadays(data.hindidatadays);
                    setHindiscore(data.hindiscore);
                    setEnglishdatadays(data.englishdatadays);
                    setEnglishscore(data.englishscore);

                    console.log("==============>",data);
                    // console.log("days===========>",data.days);
                    // console.log("english score===========>",data.englishobj);
                    // console.log("hindi score===========>",data.hindiobj);
                    // console.log("month===========>",data.month);
                    // console.log("year===========>",data.year);
                } else {
                    setLivescorearr([]);
                    setYearmonth("");
                    setDays("");
                }
                
              }}
              >
              <MenuItem value="">
                  <em>None</em>
              </MenuItem>
              {monthyeararr.map((e)=>
              <MenuItem value={e.studliveexamscore_year_month}>{e.studliveexamscore_year_month}</MenuItem>
              )}
              </Select>
            </FormControl>
              
              {/* <Grid justifyContent="center" p={1}><Button size="small" variant='contained'>Back</Button>  </Grid>
              <Grid justifyContent="center" p={1}><Button size="small" variant='contained' color='secondary'>Download</Button></Grid>     */}
          </Grid>
        </Paper>
        
        {yearmonth.length>1?
        <Grid>
        <Bar options={options} data={data1} />
        <Bar options={options} data={data2} />
        </Grid>
        :
        ''
        }
    </>
    );
  
}
