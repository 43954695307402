import React  from 'react';

import FileUploadContent from "../content/FileUploadContent";

// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";


function FileUpload() {

    
    return(
        <>
        <div className='home_bg'>
        <BackHeader />
        </div> 

        <FileUploadContent/>
       
        </>
    );
}

export default FileUpload;