import React  from 'react';

import IndexContent from "../content/IndexContent";

import Header from "../inc/Header";
// import BackHeader from "../inc/BackHeader";
import Banner from "../inc/Banner";

import { Grid } from '@mui/material';



function Home() {
    return(
        <>
        <Grid className='bg-leafgreen-home'>
        <div className='home_bg'>
        <Header /> 
        <Banner />      
        <IndexContent/>
        </div>  
        </Grid>
        </>
    );
}

export default Home;